// import logo from './logo.svg';
import './App.css';
// import crime from './images/crime_and_punishment.png'
// import don from './images/don_quixote.jpeg'
// import pride from './images/pride_and_prejudice.jpeg'
// import odyssey from './images/the_odyssey.jpeg'
// import library from './images/library.jpeg'
import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import logo from './images/451-logo.png'

function App() {

  const [clicked, setClicked] = useState(false)
  const [userEmail, setUserEmail] = useState("")

  useEffect(() => {

    if (clicked == true) {
      // this is domain for backend (heroku)
      fetch(`https://f451-club-backend.herokuapp.com/add_to_list/${userEmail}`, {
        // note -> look more into this later, just a quick fix attempt at CORS error (cross origin request)
        mode: "no-cors"
      }).then(
        res => res.json()
      ).then(
        data => {
          console.log(data)
        }
      )
      console.log('heres the email: ', userEmail)
      }

  },[clicked])
  // ------------

  return (
      <> 
      <div className='backgroundImage' style={{display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', padding: 15}}>
        <p style={{fontFamily: 'serif', color: 'lightgray', fontSize: 50, fontWeight: 'bold', textAlign: 'center'}}>Modern problems require ancient stories.</p> 
      </div>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <img src = {logo} style={{width: 200, marginBottom: -80}} />
        <p style={{fontFamily: 'futura', fontSize: 50, fontWeight: 'bold', textAlign: 'center'}}>451 Club</p>
        <p style={{fontFamily: 'futura', fontSize: 20, textAlign: 'center', fontStyle: 'italic', marginTop: -20}}>Monthly subscription of classic, time-tested novels</p>
        {
          clicked ? (
            <>
             <p style={{fontFamily: 'futura', fontSize: 20, textAlign: 'center'}}>You're now one step closer to conversating with the likes of Twain, Dostoevsky, and Orwell.  We'll be in touch soon!</p> 
            </>
          ) : (
            <Form >
              <Form.Group style={{display: 'flex', flexDirection: 'column',alignItems: 'center'}}>
                <Form.Control style={{ borderRadius: 10, padding: 10, width: 300 }} value={userEmail} onChange={val => setUserEmail(val.target.value)} type="email" placeholder='Enter your email to start your journey'/>
                <button style={{backgroundColor: '#CC9966', width: 200, borderColor: 'gray', borderRadius: 20, border: 0, marginBottom: 30, marginTop: 15}}
                  onClick={() => {
                    setClicked(true)
                    console.log('clicked')
                    }}>
                  <p style={{color: 'white', fontFamily: 'futura'}}>Get the Wisdom</p>
                </button> 
              </Form.Group>
            </Form>
          )
        }
        <div style={{width: '80%'}}>
          <p style={{fontFamily: 'futura', fontSize: 40, fontWeight: 'bold', textAlign: 'center'}}>How it Works</p>
          <p style={{fontFamily: 'futura', fontSize: 20, textAlign: 'center', marginTop: -20}}>For $15 each month, we send you a classic novel - you take it to a quiet place (preferably outside) and learn something new about humanity and the richness of life.</p> 
          <p style={{fontFamily: 'futura', fontSize: 40, fontWeight: 'bold', textAlign: 'center'}}>Why it Works</p>
          <p style={{fontFamily: 'futura', fontSize: 20, textAlign: 'center', marginTop: -20}}>Humans are creatures of story - in the modern day, we have become distracted by the speed and information overload of the internet.  The solution? Slow down and read the stories that humans of past generations turned to as guides for what it means to truly live.</p> 
          <p style={{fontFamily: 'futura', fontSize: 40, fontWeight: 'bold', textAlign: 'center'}}>Things to Note</p>
          <p style={{fontFamily: 'futura', fontSize: 20, textAlign: 'center', marginTop: -20}}>1. Every book you'll receive is used.  Just like the word-of-mouth stories of our ancestors, these books have been passed from person to person, for each to learn from and use in their own way.  Once it reaches you, what will you do with it?</p>
          <br /> 
          <p style={{fontFamily: 'futura', fontSize: 20, textAlign: 'center', marginTop: -20}}>2. One of our favorite mottos states that achieving unique outputs requires unique inputs.  For this reason, everyone receives a book randomly chosen from a carefully curated selection of classics.  Because everyone will not receive the same story, we highly encourage community discussions about the lessons and insights shared by great stories.</p>  
        </div>
        
        
      </div>
      
      </>
   
  );

  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.js</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );
}

export default App;
